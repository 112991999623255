<template>
<one-column>
  <template #content>
    <router-link :to="{name: 'dashboard'}">{{ $t('links.toDashboard.label') }}</router-link>
  </template>
</one-column>
</template>

<script>
import { mapGetters } from 'vuex'
import OneColumn from '../layouts/OneColumn.vue'
import { appSettings } from '@/settings'
import { ios, android } from 'platform-detect'

export default {
  name: 'AudioKey1',

  components: {
    OneColumn
  },

  metaInfo () {
    return {
      title: 'AudioKey'
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ])
  },

  created () {
    var country = this.user.country
    // user AT as the default country
    if (!country || !appSettings.services.audioKey1[country]) {
      country = 'AT'
    }

    const appStore = appSettings.services.audioKey1[country].ios
    const playStore = appSettings.services.audioKey1[country].android
    const fallBack = appSettings.services.audioKey1[country].fallback
    if (ios) {
      window.location.assign(appStore)
    } else if (android) {
      window.location.assign(playStore)
    } else {
      window.location.assign(fallBack)
    }
  },

  methods: {
  }
}
</script>
